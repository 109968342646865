import assign from "lodash/assign";
import Backbone from "js/libs/Backbone";
import { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import actions, { ModalOptions } from "js/main/actions";
import ConfirmModal, { ConfirmModalProps } from "js/views/widgets/ConfirmModal";

function GlobalModal() {
  const [modal, setModal] = useState<ConfirmModalProps | undefined>(undefined);

  const showModal = useCallback((msg: string, options: ModalOptions) => {
    const handleModal = (confirm: boolean) => {
      if (options && options.callback) options.callback.call(null, confirm);
      setModal(undefined);
    };

    const modalOptions: ConfirmModalProps = assign(
      {
        msg: msg,
      },
      options,
      {
        callback: handleModal,
      }
    );

    setModal(modalOptions);
  }, []);

  useEffect(() => {
    const listener = assign({}, Backbone.Events);
    listener.listenTo(actions, "showModal", showModal);
    return () => {
      listener.stopListening();
    };
  }, []);

  return modal ? <ConfirmModal {...modal} /> : null;
}

const modalContainer = document.getElementById("modalContainer");
if (modalContainer) {
  ReactDOM.render(<GlobalModal />, modalContainer);
}

let history = [];

exports.add = function(url){
  history.push(decodeURI(url));
};

exports.last = function(){
  if (history.length)
    return history[history.length - 1]
  else
    return null;
};

import Backbone from "js/libs/Backbone";
import defer from "lodash/defer";
import partial from "lodash/partial";
import zipObject from "lodash/zipObject";
import isRegExp from "lodash/isRegExp";
import isFunction from "lodash/isFunction";
import tabhistory from "js/libs/tabhistory";
import {serialize, deserialize} from "js/libs/url";
import localeStore from "js/stores/localeStore";
import actions from "js/main/actions";
import log from "js/main/log";

const namedParam = /((\(\?)?:|\*)(\w+)/g;
let initialNavigation = true;

const Router = Backbone.Router.extend({
  initialize: function(){
    this.listenTo(actions, "navigate", function(target, options) {
      if (options && options.sync) {
        this.navigate.apply(this, arguments);
      } else {
        this.slowNavigate.apply(this, arguments);
      }
    }.bind(this));
  },
  route: function(route, name, callback) {
    // Get arg names
    const argNames = [];
    let matches
    while ((matches = namedParam.exec(route)) !== null) {
      argNames.push(matches[3])
    }

    // Create route regexp
    if (!isRegExp(route)) route = this._routeToRegExp(route);

    // Unnamed routes
    if (isFunction(name)) {
      callback = name;
      name = '';
    }
    if (!callback) callback = this[name];

    var router = this;
    Backbone.history.route(route, function(fragment) {
      var args = router._extractParameters(route, fragment);
      let routename = name;

      // Attach named param list to args array
      args.match = zipObject(argNames, args);

      // Rename matched route arg for static pages
      if (name.indexOf(":") != -1 &&
        (routename.split(":")[0] == "static" ||
         routename.split(":")[0] == "hoststatic" ||
         routename.split(":")[0] == "gueststatic")){
        args[0] = routename.split(":")[1];
        args.match.subpage = routename.split(":")[1];
        routename = routename.split(":")[0];
      }


      router.execute(callback, args, routename);
      router.trigger('route:' + routename, args);
      router.trigger('route', routename, args);
      Backbone.history.trigger('route', router, routename, args);
    });
    return this;
  },
  navigate: function(fragment, options) {
    tabhistory.add(location.href);

    // Add lang param to all navigations
    [fragment,] = localeStore.replaceLangParameter(fragment);

    // Call super method
    Router.__super__.navigate.call(this, fragment, options);

    // clear OpenGraph metadata on 2nd navigation
    if (initialNavigation) {
      initialNavigation = false;
    } else {
      const tags = Array.from(document.querySelectorAll("meta"));
      tags.forEach((meta) => {
        if(/^og:.*/.test(meta.name)) {
          log("removing tag", meta.name);
          meta.parentNode.removeChild(meta);
        }
      });
    }
  },
  slowNavigate: function(target, options){
    defer(partial(this.navigate, target, options));
  },
  routes: {
    "angebot(?:query)": "offer",

    "page/(*subpage)": "static",
    "hostpage/(*subpage)": "hoststatic",
    "guestpage/(*subpage)": "gueststatic",

    "ergebnisse(?:query)": "results",
    "details/:id(?:query)": "details_1",
    ":crap/unterkunft/:id/:morecrap(?:query)": "details_2",

    "blog(/*subpage)(?:query)": "blog",
    // "blog/:id": "blogEntry",

    "buchen/:id(?:query)": "booking",
    "buchen/:id/details": "bookingInfo",

    "gastlogin(?:query)": "guestlogin",
    "gast/registrieren": "guestRegister",
    "gast/daten": "guestChange",
    "gast/buchung": "guestBookings",
    "gast/buchung/:id/stornieren": "guestBookingCancel",
    "gast/buchung/:id/beschweren": "guestBookingComplain",
    "gast/buchung/:id/bewerten": "guestBookingRate",
    "gast/buchung/:id/verlängern": "guestBookingExtend",
    "gast/buchung/:id/bestätigung": "guestBookingConfirmation",
    "gast/faq": "gueststatic:gastfaq",

    "gastgeberlogin(?:query)": "hostlogin",
    "gastgeber/registrieren": "hostRegister",
    "gastgeber/registriert": "hostRegisterConfirm",
    "gastgeber/news": "hoststatic:gastgebernews",
    "gastgeber/faq": "hoststatic:gastgeberfaq",
    "gastgeber/downloads": "hoststatic:downloadCenter",
    "gastgeber/accountdaten": "hostChange",
    "gastgeber/objekte": "hostAddresses",
    "gastgeber/objekt/anlegen": "hostAddressAdd",
    "gastgeber/objekt/:id/unterkunft/anlegen": "hostRoomtypeAdd",
    "gastgeber/objekt/:id/unterkunft/:id2/vorschau": "hostRoomtypePreview",

    // pass subpath to view for secondary navigation
    "gastgeber/objekt/:addressId/unterkunft/:roomtypeId(/*path)": "hostRoomtype",
    "gastgeber/objekt/:addressId(/*path)": "hostAddress",

    "gastgeber/mitteilungen": "hostMessage",

    "bewertung/:id(?:query)": "ratingReview",

    "*everything": "notfound"
  },
});

export default new Router();

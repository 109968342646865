/* Entry point for dynamic pages */
import "js/compat/variousPolyfills";
import "js/compat/scopeSelectorShim";
import "js/libs/common";
import isFunction from "lodash/isFunction";
import React from "react";
import ReactDOM from "react-dom";
import Backbone from "js/libs/Backbone";
import Modal from "react-modal";
import callcenterMode from "js/libs/callcenterMode";
import {findElement, getRect} from "js/libs/util";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import tracking from "js/libs/tracking";
import config from "js/main/config";
import router from "js/main/router";
import locale from "js/main/locale";
import log from "js/main/log";

gsap.registerPlugin(ScrollToPlugin);
Modal.setAppElement(document.body);
import "js/views/widgets/GlobalModals";
// import "js/views/widgets/GlobalSpinner";

// asynchronously load a View from a site bundle
let previous = null;
const loadView = function(expr, params) {
  log.info("loading", "./views/" + expr + ".jsx|tsx");
  let loader;
  try{
    loader = require("bundle-loader?lazy!./views/pages/" + expr + ".jsx");
  } catch(e) {
    try {
    loader = require("bundle-loader?lazy!./views/pages/" + expr + ".tsx");
    } catch(e) {
      throw ["loading failed for", expr, e];
    }
  }
  const container = document.getElementById("container");
  loader(function(view) {
    window.scrollTo(0, 0);
    view = view.default || view;
    if (!isFunction(view)) {
      log.error('funky element... creating:', view)
    }

    // Unmount nodes explicitely
    if (previous !== view) {
      ReactDOM.unmountComponentAtNode(container);
    }

    ReactDOM.render(React.createElement(view, params), container);
    previous = view;

    tracking.pageView(location.href.split("#")[0])
  });
};

/* Link routing */
document.body.addEventListener("click", function(event) {
  if (event.defaultPrevented)
    return;

  const anchor = findElement(event.target, function(element){
    return element.tagName == "A";
  });

  if (anchor && anchor.getAttribute("href")) {
    const href = anchor.getAttribute("href");
    const protocol = anchor.protocol + "//";

    /* Link to same page -> smoothscroll */
    if (anchor.pathname.replace(/^\//,'') == location.pathname.replace(/^\//,'') &&
        anchor.hostname == location.hostname && anchor.hash.length > 0) {
      var target = document.querySelector(anchor.hash);

      if (target) {
        const rect = getRect(target);
        gsap.to(window, {scrollTo: rect.top, duration: 1.2, onComplete: () => {
          location.hash = anchor.hash;
        }});
        event.preventDefault();
        return;
      }
    }

    const markdownParent = findElement(anchor.parentElement, function(element) {
      return element.classList.contains("markdown");
    });

    /* open markdown links in _blank */
    if (markdownParent) {
      anchor.setAttribute("target", "_blank");
      return;
    }

    /* Normally execute external Links */
    if (href.slice(0, protocol.length) == protocol ||
        anchor.getAttribute("target") == "_blank" ||
        anchor.getAttribute("data-bypass") !== null)
      return;

    /* Handle Navigation internally */
    event.preventDefault();
    router.navigate(href, {trigger: true});
  }
});

window.onerror = function(type, script, line, char, error) {
  if (type === "Script error.") {
    log.error("probably just google", arguments);
    return true;
  } else {
    if (config.env === "prod") {
      log.info("trigger reload", arguments);
      const reloads = sessionStorage.getItem("reloads") || 0
      if (Modernizr.sessionstorage && Number(reloads) < 1) {
        location.reload(true);
        sessionStorage.setItem("reloads", reloads + 1);
      }
    } else {
      log.error("things are bad", arguments);
    }
  }
}

// async app startup
let initialized = false;
window.startApp = function() {
  if (initialized)
    return;

  // patchup cities when we know our language
  if (init.cities[0].id != -1)
    init.cities.unshift({
      id: -1,
      name: locale.chooseCity,
      noLat: 48.875554,
      noLong: 9.403503,
      swLat: 48.657861,
      swLong: 8.929031
    });

  log("app startup - init:", window.init);
  initialized = true;

  // check for callcenter mode
  callcenterMode.init();
  tracking.init();

  // if init is not set then api isn't reachable
  if (!Object.prototype.hasOwnProperty.call(window, "init")) {
    log.info("init didn't load, will retry in 5 seconds");
    loadView("coffee");
    setTimeout(location.reload.bind(location, true), 5000);

  } else {

    // load corresponding view on route event
    for (const path in router.routes) {
      const name = router.routes[path];

      if (name == "frontPage")
        continue;

      if (name.indexOf(":") !== -1 &&
         (name.split(":")[0] == "static" ||
         name.split(":")[0] == "hoststatic" ||
         name.split(":")[0] == "gueststatic")) {
        continue;
      }

      /* eslint-disable no-loop-func */
      (function(route) {
        let target = route;
        if (target.indexOf("_") !== -1) {
          target = target.split("_")[0];
        }
        router.on("route:" + route, loadView.bind(null, target));
      }(name));
      /* eslint-enable no-loop-func */
    }
  }

  Backbone.history.start({pushState: true});
};
